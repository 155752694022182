.logo {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 78px;
  margin-top: 1.62px;
  min-width: 286px;
}

.clarityhelix-solid {
  align-self: center;
  height: 40px;
  margin-bottom: 1.54px;
  width: 40px;
}

.place-1 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: 52.3px;
  font-weight: 700;
  letter-spacing: 13.07px;
  line-height: 78.4px;
  margin-top: -1.54px;
  white-space: nowrap;
}

.logo.logo-1 {
  gap: 3px;
  height: 36px;
  margin-top: 0.65px;
  min-width: 130px;
}

.logo.logo-1 .clarityhelix-solid {
  height: 18px;
  margin-bottom: 0.7px;
  width: 18px;
}

.logo.logo-1 .place-1 {
  font-size: 23.8px;
  letter-spacing: 5.94px;
  line-height: 35.6px;
  margin-top: -0.7px;
  min-height: 36px;
}

.logo.logo-2 {
  gap: 4px;
  height: 39px;
  margin-top: 0.81px;
  min-width: 143px;
}

.logo.logo-2 .clarityhelix-solid {
  height: 20px;
  margin-bottom: 0.77px;
  width: 20px;
}

.logo.logo-2 .place-1 {
  font-size: 26.1px;
  letter-spacing: 6.53px;
  line-height: 39.2px;
  margin-top: -0.77px;
}

.logo.logo-3 {
  gap: 3px;
  height: 28px;
  justify-content: flex-end;
  margin-top: 0.11px;
  min-width: 103px;
}

.logo.logo-3 .clarityhelix-solid {
  height: 14px;
  margin-bottom: 0.55px;
  width: 14px;
}

.logo.logo-3 .place-1 {
  font-size: 18.8px;
  letter-spacing: 4.70px;
  line-height: 28.2px;
  margin-top: -0.55px;
}

