@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Alexandria:400,700,800";
.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

.alpha-white-1366 {
  background-color: var(--coconut);
  height: 883px;
  width: 1366px;
  border: .79px;
  align-items: flex-start;
  display: flex;
}

.overlap-group1-3 {
  min-height: 883px;
  width: 1366px;
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.flex-row-3 {
  height: 33px;
  min-width: 1208px;
  align-self: center;
  align-items: center;
  gap: 1004px;
  margin-top: 40px;
  margin-left: .27px;
  display: flex;
  position: relative;
}

.frame-2-3 {
  background-color: var(--robins-egg-blue);
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-start;
  gap: 7.91px;
  padding: 7.91px 23.72px;
  display: flex;
}

.place-4 {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -.79px;
  font-size: 11.1px;
  font-weight: 400;
  line-height: 16.6px;
}

.line-2-3 {
  height: 1px;
  width: 1366px;
  margin-top: 38px;
}

.group-5-3 {
  min-width: 999px;
  align-self: center;
  align-items: flex-end;
  gap: 62px;
  margin-top: 73px;
  margin-left: 3.29px;
  display: flex;
}

.group-4-3 {
  min-width: 519px;
  align-items: flex-start;
  gap: 55px;
  display: flex;
}

.group-3-3 {
  min-height: 205px;
  width: 463px;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.line-1-3 {
  height: 9px;
  width: 102px;
  margin-right: 6.96px;
}

.overlap-group-3 {
  height: 153px;
  width: 457px;
  align-self: flex-start;
  margin-top: 10px;
  position: relative;
}

.alpha-dna-3 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 56px;
  font-weight: 800;
  line-height: 84px;
  position: absolute;
  top: 0;
  left: 108px;
}

.technologies-3 {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  font-size: 56px;
  font-weight: 800;
  line-height: 84px;
  position: absolute;
  top: 69px;
  left: 0;
}

.building-the-future-3 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  min-width: 229px;
  white-space: nowrap;
  margin-right: 6.96px;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
}

.rectangle-8-3 {
  background-color: var(--robins-egg-blue);
  height: 485px;
  width: 2px;
  align-self: flex-end;
  margin-bottom: 0;
}

.group-2-3 {
  min-height: 271px;
  width: 421px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  margin-bottom: 0;
  display: flex;
}

.about-us-3 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 700;
  line-height: 25.2px;
}

.at-our-software-deve-3 {
  letter-spacing: 0;
  min-height: 227px;
  width: 417px;
  margin-left: 0;
  line-height: 25.2px;
}

.overlap-group2-3 {
  min-width: 1230px;
  background-image: url("line-3-5@1x.373d83b4.svg");
  background-size: 100% 100%;
  align-items: flex-start;
  margin-top: 74px;
  display: flex;
}

.line-4-3 {
  height: 1px;
  width: 1230px;
}

.overlap-group3-3 {
  height: 136px;
  width: 1231px;
  margin-top: 2px;
  position: relative;
}

.copyright-3 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 160px;
  font-size: 12px;
  font-weight: 400;
  line-height: 21.6px;
  position: absolute;
  top: 58px;
  left: 77px;
}

.mask-group-3 {
  height: 136px;
  width: 1231px;
  position: absolute;
  top: 0;
  left: 0;
}

.alpha-white-1920 {
  background-color: var(--coconut);
  height: 1228.7px;
  width: 1900.8px;
  border: 1.1px;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.overlap-group1-2 {
  min-height: 1229px;
  width: 1901px;
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.flex-row-2 {
  height: 45px;
  min-width: 1680px;
  align-self: center;
  align-items: center;
  gap: 1397px;
  margin-top: 55px;
  margin-right: .8px;
  display: flex;
  position: relative;
}

.frame-2-2 {
  background-color: var(--robins-egg-blue);
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-start;
  gap: 11px;
  padding: 11px 33px;
  display: flex;
}

.place-3 {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1.1px;
  font-size: 15.4px;
  font-weight: 400;
  line-height: 23.1px;
}

.line-2-2 {
  height: 1px;
  width: 1901px;
  margin-top: 54px;
}

.group-5-2 {
  min-width: 1365px;
  align-items: flex-end;
  gap: 85px;
  margin-top: 102px;
  margin-right: 110.59px;
  display: flex;
}

.group-4-2 {
  min-width: 714px;
  align-items: flex-start;
  gap: 78px;
  display: flex;
}

.group-3-2 {
  min-height: 286px;
  width: 634px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  padding: 0;
  display: flex;
}

.line-1-2 {
  height: 12px;
  width: 138px;
  align-self: flex-end;
  margin-right: 6px;
}

.overlap-group-2 {
  height: 260px;
  width: 628px;
  position: relative;
}

.alpha-dna-2 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 479px;
  font-size: 79px;
  font-weight: 800;
  line-height: 118.5px;
  position: absolute;
  top: 0;
  left: 149px;
}

.technologies-2 {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 628px;
  font-size: 79px;
  font-weight: 800;
  line-height: 118.5px;
  position: absolute;
  top: 97px;
  left: 0;
}

.building-the-future-2 {
  letter-spacing: 0;
  white-space: nowrap;
  width: 304px;
  line-height: 45px;
  position: absolute;
  top: 215px;
  left: 316px;
}

.rectangle-8-2 {
  background-color: var(--robins-egg-blue);
  height: 675px;
  width: 2px;
  align-self: flex-end;
}

.group-2-2 {
  min-height: 377px;
  width: 570px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  margin-bottom: 0;
  display: flex;
}

.about-us-2 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 700;
  line-height: 36px;
}

.at-our-software-deve-2 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  min-height: 316px;
  width: 566px;
  font-weight: 400;
  line-height: 36px;
}

.overlap-group2-2 {
  min-width: 1717px;
  background-image: url("line-3-3@1x.6386b6d2.svg");
  background-size: 100% 100%;
  align-items: flex-start;
  margin-top: 103px;
  display: flex;
}

.line-4-2 {
  height: 1px;
  width: 1717px;
}

.overlap-group3-2 {
  height: 189px;
  width: 1717px;
  margin-top: 2px;
  position: relative;
}

.copyright-2 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 200px;
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  position: absolute;
  top: 81px;
  left: 107px;
}

.mask-group-2 {
  height: 189px;
  width: 1717px;
  position: absolute;
  top: 0;
  left: 0;
}

.alpha-white {
  background-color: var(--coconut);
  height: 1117px;
  width: 1728px;
  border: 1px;
  align-items: flex-start;
  display: flex;
}

.overlap-group1-1 {
  min-height: 1117px;
  width: 1728px;
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.flex-row-1 {
  height: 41px;
  min-width: 1528px;
  align-self: center;
  align-items: center;
  gap: 1270px;
  margin-top: 50px;
  display: flex;
  position: relative;
}

.frame-2-1 {
  background-color: var(--robins-egg-blue);
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 30px;
  display: flex;
}

.place-2 {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-weight: 400;
  line-height: 21px;
}

.line-2-1 {
  height: 1px;
  object-fit: cover;
  width: 1728px;
  margin-top: 49px;
}

.group-5-1 {
  min-width: 1241px;
  align-items: flex-end;
  gap: 77px;
  margin-top: 93px;
  margin-right: 25.72px;
  display: flex;
}

.group-4-1 {
  min-width: 649px;
  align-items: flex-start;
  gap: 70px;
  display: flex;
}

.group-3-1 {
  min-height: 261px;
  width: 577px;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  display: flex;
}

.line-1-1 {
  height: 11px;
  width: 126px;
  align-self: flex-end;
  margin-right: 6.09px;
}

.overlap-group-1 {
  height: 237px;
  width: 571px;
  position: relative;
}

.alpha-dna-1 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 436px;
  font-size: 71.9px;
  font-weight: 800;
  line-height: 107.9px;
  position: absolute;
  top: 0;
  left: 136px;
}

.technologies-1 {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 571px;
  font-size: 71.9px;
  font-weight: 800;
  line-height: 107.9px;
  position: absolute;
  top: 88px;
  left: 0;
}

.building-the-future-1 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
  white-space: nowrap;
  width: 284px;
  font-weight: 400;
  line-height: 42px;
  position: absolute;
  top: 195px;
  left: 288px;
}

.rectangle-8-1 {
  background-color: var(--robins-egg-blue);
  height: 614px;
  width: 2px;
  align-self: flex-end;
}

.group-2-1 {
  min-height: 343px;
  width: 519px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.about-us-1 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 700;
  line-height: 32.4px;
}

.at-our-software-deve-1 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  min-height: 287px;
  width: 515px;
  font-weight: 400;
  line-height: 32.4px;
}

.overlap-group3-1 {
  min-width: 1486px;
  background-image: url("line-3@1x.1be4fda0.svg");
  background-position: 50%;
  background-size: cover;
  align-items: flex-start;
  margin-top: 94px;
  display: flex;
}

.line-4-1 {
  height: 1px;
  object-fit: cover;
  width: 1486px;
}

.overlap-group2-1 {
  height: 172px;
  width: 1628px;
  margin-top: 2px;
  position: relative;
}

.copyright-1 {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 25.2px;
  position: absolute;
  top: 63px;
  left: 0;
}

.mask-group-1 {
  height: 172px;
  width: 1472px;
  position: absolute;
  top: 0;
  left: 156px;
}

.alpha-white-3840 {
  background-color: var(--coconut);
  height: 2457.4px;
  width: 3801.6px;
  border: 2.2px;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.overlap-group1 {
  min-height: 2457px;
  width: 3802px;
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.flex-row {
  height: 90px;
  min-width: 3360px;
  align-self: center;
  align-items: center;
  gap: 2794px;
  margin-top: 110px;
  margin-right: 1.6px;
  display: flex;
  position: relative;
}

.frame-2 {
  background-color: var(--robins-egg-blue);
  width: -moz-fit-content;
  width: fit-content;
  align-items: flex-start;
  gap: 22px;
  padding: 22px 66px;
  display: flex;
}

.place {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -2.2px;
  font-size: 30.8px;
  font-weight: 400;
  line-height: 46.2px;
}

.line-2 {
  height: 2px;
  width: 3802px;
  margin-top: 108px;
}

.group-5 {
  min-width: 2180px;
  align-self: center;
  align-items: flex-end;
  gap: 136px;
  margin-top: 340px;
  margin-left: 98.4px;
  display: flex;
}

.group-4 {
  min-width: 1139px;
  align-items: flex-start;
  gap: 128px;
  display: flex;
}

.group-3 {
  min-height: 457px;
  width: 1007px;
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  margin-top: 0;
  display: flex;
}

.line-1 {
  height: 19px;
  width: 221px;
  align-self: flex-end;
  margin-right: 6px;
}

.overlap-group {
  height: 415px;
  width: 1001px;
  position: relative;
}

.alpha-dna {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 763px;
  font-size: 126px;
  font-weight: 800;
  line-height: 189px;
  position: absolute;
  top: 0;
  left: 234px;
}

.technologies {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  letter-spacing: 0;
  white-space: nowrap;
  width: 1001px;
  font-size: 126px;
  font-weight: 800;
  line-height: 189px;
  position: absolute;
  top: 155px;
  left: 0;
}

.building-the-future {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxxxl);
  letter-spacing: 0;
  white-space: nowrap;
  width: 487px;
  font-weight: 400;
  line-height: 72px;
  position: absolute;
  top: 343px;
  left: 502px;
}

.rectangle-8 {
  background-color: var(--robins-egg-blue);
  height: 1081px;
  width: 3px;
}

.group-2 {
  min-height: 604px;
  width: 910px;
  flex-direction: column;
  align-items: flex-start;
  gap: 41px;
  margin-bottom: 0;
  display: flex;
}

.about-us {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxxl);
  letter-spacing: 0;
  white-space: nowrap;
  font-weight: 700;
  line-height: 57.6px;
}

.at-our-software-deve {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxxl);
  letter-spacing: 0;
  min-height: 505px;
  width: 906px;
  font-weight: 400;
  line-height: 57.6px;
}

.overlap-group3 {
  min-width: 3702px;
  background-image: url("line-3-1@1x.7076dc26.svg");
  background-size: 100% 100%;
  align-items: flex-start;
  margin-top: 342px;
  display: flex;
}

.line-4 {
  height: 2px;
  width: 3702px;
}

.overlap-group2 {
  height: 378px;
  width: 3702px;
  margin-top: 4px;
  position: relative;
}

.copyright {
  letter-spacing: 0;
  white-space: nowrap;
  width: 398px;
  line-height: 54px;
  position: absolute;
  top: 162px;
  left: 215px;
}

.mask-group {
  height: 378px;
  width: 3702px;
  position: absolute;
  top: 0;
  left: 0;
}

.logo {
  height: 78px;
  min-width: 286px;
  align-items: flex-start;
  gap: 8px;
  margin-top: 1.62px;
  display: flex;
}

.clarityhelix-solid {
  height: 40px;
  width: 40px;
  align-self: center;
  margin-bottom: 1.54px;
}

.place-1 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  letter-spacing: 13.07px;
  white-space: nowrap;
  margin-top: -1.54px;
  font-size: 52.3px;
  font-weight: 700;
  line-height: 78.4px;
}

.logo.logo-1 {
  height: 36px;
  min-width: 130px;
  gap: 3px;
  margin-top: .65px;
}

.logo.logo-1 .clarityhelix-solid {
  height: 18px;
  width: 18px;
  margin-bottom: .7px;
}

.logo.logo-1 .place-1 {
  letter-spacing: 5.94px;
  min-height: 36px;
  margin-top: -.7px;
  font-size: 23.8px;
  line-height: 35.6px;
}

.logo.logo-2 {
  height: 39px;
  min-width: 143px;
  gap: 4px;
  margin-top: .81px;
}

.logo.logo-2 .clarityhelix-solid {
  height: 20px;
  width: 20px;
  margin-bottom: .77px;
}

.logo.logo-2 .place-1 {
  letter-spacing: 6.53px;
  margin-top: -.77px;
  font-size: 26.1px;
  line-height: 39.2px;
}

.logo.logo-3 {
  height: 28px;
  min-width: 103px;
  justify-content: flex-end;
  gap: 3px;
  margin-top: .11px;
}

.logo.logo-3 .clarityhelix-solid {
  height: 14px;
  width: 14px;
  margin-bottom: .55px;
}

.logo.logo-3 .place-1 {
  letter-spacing: 4.7px;
  margin-top: -.55px;
  font-size: 18.8px;
  line-height: 28.2px;
}



:root {
  --blue-charcoal: #000615;
  --coconut: #f9feff;
  --dark--system-black: #000;
  --robins-egg-blue: #12ced0;
  --scarpa-flow: #52555d;
  --white--primary: #fff;
  --font-size-l: 18px;
  --font-size-m: 14px;
  --font-size-xl: 20px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 30px;
  --font-size-xxxxl: 32px;
  --font-size-xxxxxl: 48px;
  --font-family-alexandria: "Alexandria", Helvetica;
}

.alexandria-normal-scarpa-flow-30px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.alexandria-normal-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

/*# sourceMappingURL=index.43de1ef1.css.map */
