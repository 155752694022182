.alpha-white-1920 {
  align-items: flex-start;
  background-color: var(--coconut);
  border: 1.1px none;
  display: flex;
  height: 1228.7px;
  overflow: hidden;
  width: 1900.8px;
}

.overlap-group1-2 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 1229px;
  width: 1901px;
}

.flex-row-2 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 1397px;
  height: 45px;
  margin-right: 0.8px;
  margin-top: 55px;
  min-width: 1680px;
  position: relative;
}

.frame-2-2 {
  align-items: flex-start;
  background-color: var(--robins-egg-blue);
  display: flex;
  gap: 11px;
  padding: 11px 33px;
  width: fit-content;
}

.place-3 {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  font-size: 15.4px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
  margin-top: -1.10px;
  white-space: nowrap;
  width: fit-content;
}

.line-2-2 {
  height: 1px;
  margin-top: 54px;
  width: 1901px;
}

.group-5-2 {
  align-items: flex-end;
  display: flex;
  gap: 85px;
  margin-right: 110.59px;
  margin-top: 102px;
  min-width: 1365px;
}

.group-4-2 {
  align-items: flex-start;
  display: flex;
  gap: 78px;
  min-width: 714px;
}

.group-3-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 14px;
  min-height: 286px;
  padding: 0 0.0px;
  width: 634px;
}

.line-1-2 {
  align-self: flex-end;
  height: 12px;
  margin-right: 6.0px;
  width: 138px;
}

.overlap-group-2 {
  height: 260px;
  position: relative;
  width: 628px;
}

.alpha-dna-2 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: 79px;
  font-weight: 800;
  left: 149px;
  letter-spacing: 0;
  line-height: 118.5px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 479px;
}

.technologies-2 {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  font-size: 79px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: 118.5px;
  position: absolute;
  top: 97px;
  white-space: nowrap;
  width: 628px;
}

.building-the-future-2 {
  left: 316px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 215px;
  white-space: nowrap;
  width: 304px;
}

.rectangle-8-2 {
  align-self: flex-end;
  background-color: var(--robins-egg-blue);
  height: 675px;
  width: 2px;
}

.group-2-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin-bottom: 0;
  min-height: 377px;
  width: 570px;
}

.about-us-2 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 36.0px;
  white-space: nowrap;
}

.at-our-software-deve-2 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 36.0px;
  min-height: 316px;
  width: 566px;
}

.overlap-group2-2 {
  align-items: flex-start;
  background-image: url(/static/img/line-3-3@1x.svg);
  background-size: 100% 100%;
  display: flex;
  margin-top: 103px;
  min-width: 1717px;
}

.line-4-2 {
  height: 1px;
  width: 1717px;
}

.overlap-group3-2 {
  height: 189px;
  margin-top: 2px;
  position: relative;
  width: 1717px;
}

.copyright-2 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: 15px;
  font-weight: 400;
  left: 107px;
  letter-spacing: 0;
  line-height: 27.0px;
  position: absolute;
  top: 81px;
  white-space: nowrap;
  width: 200px;
}

.mask-group-2 {
  height: 189px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1717px;
}

