:root { 
  --blue-charcoal: #000615;
  --coconut: #f9feff;
  --dark--system-black: #000000;
  --robins-egg-blue: #12ced0;
  --scarpa-flow: #52555d;
  --white--primary: #ffffff;
 
  --font-size-l: 18px;
  --font-size-m: 14px;
  --font-size-xl: 20px;
  --font-size-xxl: 28px;
  --font-size-xxxl: 30px;
  --font-size-xxxxl: 32px;
  --font-size-xxxxxl: 48px;
 
  --font-family-alexandria: "Alexandria", Helvetica;
}
.alexandria-normal-scarpa-flow-30px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.alexandria-normal-scarpa-flow-14px {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}
