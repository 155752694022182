.alpha-white {
  align-items: flex-start;
  background-color: var(--coconut);
  border: 1px none;
  display: flex;
  height: 1117px;
  width: 1728px;
}

.overlap-group1-1 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 1117px;
  width: 1728px;
}

.flex-row-1 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 1270px;
  height: 41px;
  margin-top: 50px;
  min-width: 1528px;
  position: relative;
}

.frame-2-1 {
  align-items: flex-start;
  background-color: var(--robins-egg-blue);
  display: flex;
  gap: 10px;
  padding: 10px 30px;
  width: fit-content;
}

.place-2 {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1.00px;
  white-space: nowrap;
  width: fit-content;
}

.line-2-1 {
  height: 1px;
  margin-top: 49px;
  object-fit: cover;
  width: 1728px;
}

.group-5-1 {
  align-items: flex-end;
  display: flex;
  gap: 77px;
  margin-right: 25.72px;
  margin-top: 93px;
  min-width: 1241px;
}

.group-4-1 {
  align-items: flex-start;
  display: flex;
  gap: 70px;
  min-width: 649px;
}

.group-3-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  min-height: 261px;
  width: 577px;
}

.line-1-1 {
  align-self: flex-end;
  height: 11px;
  margin-right: 6.09px;
  width: 126px;
}

.overlap-group-1 {
  height: 237px;
  position: relative;
  width: 571px;
}

.alpha-dna-1 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: 71.9px;
  font-weight: 800;
  left: 136px;
  letter-spacing: 0;
  line-height: 107.9px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 436px;
}

.technologies-1 {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  font-size: 71.9px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: 107.9px;
  position: absolute;
  top: 88px;
  white-space: nowrap;
  width: 571px;
}

.building-the-future-1 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  left: 288px;
  letter-spacing: 0;
  line-height: 42px;
  position: absolute;
  top: 195px;
  white-space: nowrap;
  width: 284px;
}

.rectangle-8-1 {
  align-self: flex-end;
  background-color: var(--robins-egg-blue);
  height: 614px;
  width: 2px;
}

.group-2-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 343px;
  width: 519px;
}

.about-us-1 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-l);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 32.4px;
  white-space: nowrap;
}

.at-our-software-deve-1 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-l);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 32.4px;
  min-height: 287px;
  width: 515px;
}

.overlap-group3-1 {
  align-items: flex-start;
  background-image: url(/static/img/line-3@1x.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  margin-top: 94px;
  min-width: 1486px;
}

.line-4-1 {
  height: 1px;
  object-fit: cover;
  width: 1486px;
}

.overlap-group2-1 {
  height: 172px;
  margin-top: 2px;
  position: relative;
  width: 1628px;
}

.copyright-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 25.2px;
  position: absolute;
  top: 63px;
  white-space: nowrap;
}

.mask-group-1 {
  height: 172px;
  left: 156px;
  position: absolute;
  top: 0;
  width: 1472px;
}

