.alpha-white-1366 {
  align-items: flex-start;
  background-color: var(--coconut);
  border: 0.79px none;
  display: flex;
  height: 883.0px;
  width: 1366px;
}

.overlap-group1-3 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 883px;
  width: 1366px;
}

.flex-row-3 {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 1004px;
  height: 33px;
  margin-left: 0.27px;
  margin-top: 40px;
  min-width: 1208px;
  position: relative;
}

.frame-2-3 {
  align-items: flex-start;
  background-color: var(--robins-egg-blue);
  display: flex;
  gap: 7.91px;
  padding: 7.91px 23.72px;
  width: fit-content;
}

.place-4 {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  font-size: 11.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.6px;
  margin-top: -0.79px;
  white-space: nowrap;
  width: fit-content;
}

.line-2-3 {
  height: 1px;
  margin-top: 38px;
  width: 1366px;
}

.group-5-3 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  gap: 62px;
  margin-left: 3.29px;
  margin-top: 73px;
  min-width: 999px;
}

.group-4-3 {
  align-items: flex-start;
  display: flex;
  gap: 55px;
  min-width: 519px;
}

.group-3-3 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 205px;
  width: 463px;
}

.line-1-3 {
  height: 9px;
  margin-right: 6.96px;
  width: 102px;
}

.overlap-group-3 {
  align-self: flex-start;
  height: 153px;
  margin-top: 10px;
  position: relative;
  width: 457px;
}

.alpha-dna-3 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: 56px;
  font-weight: 800;
  left: 108px;
  letter-spacing: 0;
  line-height: 84px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.technologies-3 {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  font-size: 56px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: 84px;
  position: absolute;
  top: 69px;
  white-space: nowrap;
}

.building-the-future-3 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33px;
  margin-right: 6.96px;
  min-width: 229px;
  white-space: nowrap;
}

.rectangle-8-3 {
  align-self: flex-end;
  background-color: var(--robins-egg-blue);
  height: 485px;
  margin-bottom: 0;
  width: 2px;
}

.group-2-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-bottom: 0;
  min-height: 271px;
  width: 421px;
}

.about-us-3 {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-m);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.2px;
  white-space: nowrap;
}

.at-our-software-deve-3 {
  letter-spacing: 0;
  line-height: 25.2px;
  margin-left: 0;
  min-height: 227px;
  width: 417px;
}

.overlap-group2-3 {
  align-items: flex-start;
  background-image: url(/static/img/line-3-5@1x.svg);
  background-size: 100% 100%;
  display: flex;
  margin-top: 74px;
  min-width: 1230px;
}

.line-4-3 {
  height: 1px;
  width: 1230px;
}

.overlap-group3-3 {
  height: 136px;
  margin-top: 2px;
  position: relative;
  width: 1231px;
}

.copyright-3 {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: 12px;
  font-weight: 400;
  left: 77px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 58px;
  white-space: nowrap;
  width: 160px;
}

.mask-group-3 {
  height: 136px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1231px;
}

