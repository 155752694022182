.alpha-white-3840 {
  align-items: flex-start;
  background-color: var(--coconut);
  border: 2.2px none;
  display: flex;
  height: 2457.4px;
  overflow: hidden;
  width: 3801.6px;
}

.overlap-group1 {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 2457px;
  width: 3802px;
}

.flex-row {
  align-items: center;
  align-self: center;
  display: flex;
  gap: 2794px;
  height: 90px;
  margin-right: 1.6px;
  margin-top: 110px;
  min-width: 3360px;
  position: relative;
}

.frame-2 {
  align-items: flex-start;
  background-color: var(--robins-egg-blue);
  display: flex;
  gap: 22px;
  padding: 22px 66px;
  width: fit-content;
}

.place {
  color: var(--white--primary);
  font-family: var(--font-family-alexandria);
  font-size: 30.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 46.2px;
  margin-top: -2.20px;
  white-space: nowrap;
  width: fit-content;
}

.line-2 {
  height: 2px;
  margin-top: 108px;
  width: 3802px;
}

.group-5 {
  align-items: flex-end;
  align-self: center;
  display: flex;
  gap: 136px;
  margin-left: 98.4px;
  margin-top: 340px;
  min-width: 2180px;
}

.group-4 {
  align-items: flex-start;
  display: flex;
  gap: 128px;
  min-width: 1139px;
}

.group-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: 0;
  min-height: 457px;
  width: 1007px;
}

.line-1 {
  align-self: flex-end;
  height: 19px;
  margin-right: 6.0px;
  width: 221px;
}

.overlap-group {
  height: 415px;
  position: relative;
  width: 1001px;
}

.alpha-dna {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: 126px;
  font-weight: 800;
  left: 234px;
  letter-spacing: 0;
  line-height: 189px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 763px;
}

.technologies {
  color: var(--robins-egg-blue);
  font-family: var(--font-family-alexandria);
  font-size: 126px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: 189px;
  position: absolute;
  top: 155px;
  white-space: nowrap;
  width: 1001px;
}

.building-the-future {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxxxl);
  font-weight: 400;
  left: 502px;
  letter-spacing: 0;
  line-height: 72.0px;
  position: absolute;
  top: 343px;
  white-space: nowrap;
  width: 487px;
}

.rectangle-8 {
  background-color: var(--robins-egg-blue);
  height: 1081px;
  width: 3px;
}

.group-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 41px;
  margin-bottom: 0;
  min-height: 604px;
  width: 910px;
}

.about-us {
  color: var(--blue-charcoal);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 57.6px;
  white-space: nowrap;
}

.at-our-software-deve {
  color: var(--scarpa-flow);
  font-family: var(--font-family-alexandria);
  font-size: var(--font-size-xxxxl);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 57.6px;
  min-height: 505px;
  width: 906px;
}

.overlap-group3 {
  align-items: flex-start;
  background-image: url(/static/img/line-3-1@1x.svg);
  background-size: 100% 100%;
  display: flex;
  margin-top: 342px;
  min-width: 3702px;
}

.line-4 {
  height: 2px;
  width: 3702px;
}

.overlap-group2 {
  height: 378px;
  margin-top: 4px;
  position: relative;
  width: 3702px;
}

.copyright {
  left: 215px;
  letter-spacing: 0;
  line-height: 54.0px;
  position: absolute;
  top: 162px;
  white-space: nowrap;
  width: 398px;
}

.mask-group {
  height: 378px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3702px;
}

